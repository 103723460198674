<template>
  <div>
    <div class="title">
      <div>保单号</div>
      <div>担保公司</div>
      <div>支付时间</div>
      <div>保单状态</div>
      <div>操作</div>
    </div>
    <ul class="list">
      <li class="item">
        <div class="header">
          <div class="no">
            业务流水号&nbsp;&nbsp;&nbsp;&nbsp;YBDF202038DHHD99999231823919238741294
          </div>
          <div class="time">
            提交时间&nbsp;&nbsp;&nbsp;&nbsp;2020-12-12 04:20:23
          </div>
        </div>
        <div class="main">
          <div>SVGN22229202012124544</div>
          <div>锦泰财产保险股份有限公司</div>
          <div>2020-12-12 04:12:33</div>
          <div>已生效</div>
          <div>
            <a href="">查看</a>
          </div>
        </div>
      </li>
      <li class="item">
        <div class="header">
          <div class="no">
            业务流水号&nbsp;&nbsp;&nbsp;&nbsp;YBDF202038DHHD99999231823919238741294
          </div>
          <div class="time">
            提交时间&nbsp;&nbsp;&nbsp;&nbsp;2020-12-12 04:20:23
          </div>
        </div>
        <div class="main">
          <div>SVGN22229202012124544</div>
          <div>锦泰财产保险股份有限公司</div>
          <div>2020-12-12 04:12:33</div>
          <div>已生效</div>
          <div>
            <a href="">查看</a>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="less" scoped>

.title{
  background-color: #F5F6FB;
  font-size: 16px;
  color:#666;
  overflow: hidden;
  padding:20px 25px;
}
.title>div,.item .main>div{
  float: left;
  &:nth-child(1){
    width: 240px;
  }
  &:nth-child(2){
    width: 260px;
  }
  &:nth-child(3){
    width: 220px;
  }
  &:nth-child(4){
    width: 180px;
  }
  &:nth-child(5){
    width: 120px;
  }
}
.list{
  margin-top: 15px;
  ul {
    list-style-type: none;
    padding: 0;
  }
  .item{
    border: 1px solid #E5E5E5;
    &+.item{
      margin-top: 15px;
    }
    .header{
      overflow: hidden;
      padding:15px 25px;
      background: rgba(0, 124, 226, 0.05);
      border-bottom: 1px solid #E5E5E5;
      font-size: 14px;
      color:#999;
      .no{
        float: left;
      }
      .time{
        float: right;
        text-align: right;
      }
    }
    .main{
      overflow: hidden;
      padding:40px 25px;
    }
  }
}
</style>
