<template>
  <div class="container">
    <PlateTitle title="投保历史记录" />
    <List class="list" />
    <a-pagination
      class="pagination"
      :default-current="6"
      :total="500"
    />
  </div>
</template>

<script>
import { Pagination } from 'ant-design-vue';
import PlateTitle from '@/components/PlateTitle';
import List from './components/List';
export default {
  name: 'HistoryPage',
  components: {
    aPagination: Pagination,
    PlateTitle,
    List,
  },
};
</script>

<style lang="less" scoped>
.container{
  background-color: #fff;
  padding: 30px 50px;
}
.list{
  margin-top: 20px;
}
.pagination{
  margin-top: 30px;
  text-align: center;
}
</style>
